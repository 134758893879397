import ErpSupplierList from "./list";
import ErpSupplierShow from "./show";


if (document.body.getAttribute("data-current-path") === "erp/suppliers") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new ErpSupplierList();
    } else if(action === "show") {
        new ErpSupplierShow();
    }
}